import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

//components
import { Menu } from "antd";

//style
import style from "./MobileMenu.module.css";

export const MobileMenu: React.FC = () => {
  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const onKeyClick = ({ key }: { key: string }) => setSelectedKeys([key]);
  return (
    <Menu
      mode="horizontal"
      inlineCollapsed={false}
      selectedKeys={selectedKeys}
      onClick={onKeyClick}
      className={style.mobileMenu}
    >
      <Menu.Item key="/">
        <Link to="/">Группы</Link>
      </Menu.Item>

      <Menu.Item key="/lamps">
        <Link to="/lamps">Светильники</Link>
      </Menu.Item>

      <Menu.Item key="/timetable">
        <Link to="/timetable">Расписание</Link>
      </Menu.Item>
    </Menu>
  );
};
