import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { StrictMode } from "react";
import { ConfigProvider } from "antd";

// componets
import { App } from "./app/App";

// utils
import store from "app/store";
import reportWebVitals from "./reportWebVitals";
import { history } from "utils/history";
import "./lib/yup";

// locale
import ruRu from "antd/lib/locale/ru_RU";

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={ruRu}>
        <Router history={history}>
          <App />
        </Router>
      </ConfigProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
