import { useEffect } from "react";
import { useFormik, FormikProvider } from "formik";
import { object } from "yup";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

//components
import { Input, FormItem } from "formik-antd";
import { Button, Form, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// styles
import style from "./LoginForm.module.css";

//utils
import {
  usernameSchema,
  passwordSchema,
} from "feat/auth/utils/validationSchema";
import { history } from "utils/history";
import { login } from "feat/auth/actions";

const { Text } = Typography;

export const LoginForm = () => {
  const dispatch = useDispatch();

  const loggedUser = localStorage.getItem("username");

  const validationSchema = object({
    username: usernameSchema(),
    password: passwordSchema(),
  });

  useEffect(() => {
    if (loggedUser) {
      history.push("/");
    }
  }, [loggedUser]);

  const formikCtx = useFormik({
    validationSchema,
    validateOnBlur: false,
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async ({ username, password }) => {
      dispatch(login({ username, password }));
    },
  });

  const { handleSubmit } = formikCtx;

  return (
    <div className={style.login}>
      <FormikProvider value={formikCtx}>
        <Form className={style.loginForm} onFinish={handleSubmit}>
          <Helmet>
            <title>Вход | Система управления наружным освещением</title>
          </Helmet>

          <div className={style.logo}>
            <Text>Вход в uSpherum</Text>
          </div>

          <FormItem name="username">
            <Input
              prefix={<UserOutlined />}
              placeholder="Логин"
              name="username"
            />
          </FormItem>

          <FormItem name="password">
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Пароль"
              name="password"
            />
          </FormItem>

          <Button type="primary" htmlType="submit" className={style.button}>
            Войти
          </Button>
        </Form>
      </FormikProvider>
    </div>
  );
};
