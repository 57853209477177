import style from "./AppLogo.module.css";

export const AppLogo: React.FC = () => {
  return (
    <svg
      className={style.logo}
      version="1.1"
      id="Слой_2"
      xmlns="http://www.w3.org/2000/svg"
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 309 311.9"
      enableBackground="new 0 0 309 311.9"
      // xml:space="preserve"
    >
      <g id="XMLID_1_">
        <g>
          <path
            fill="#dfe9ed"
            d="M300,103.3c5.8,16.5,9,34.2,9,52.7c0,71.7-47.9,132.1-113.1,150.4v0c4.9-77.9,29.8-105.7,30.5-106.5			C227.2,198.7,293.8,156,300,103.3z"
          />
          <path
            fill="#dfe9ed"
            d="M280.6,65.8c2.8,27.5-13.1,50.1-35.3,69.7c-14.9,13.9-59,36.1-74,32.2c11.9-6.8,20.1-21.6,20.1-38.7			c0-23.6-15.5-42.7-34.7-42.7c-19.1,0-34.7,19.1-34.7,42.7c0,17.6,8.6,32.7,21,39.2l0,0.1c-4.1-0.1-21.7-2.7-41.1-11.6			c-11.1-5.1-20.7-11.1-29.7-17.9c-40.4-30.5-42.3-75-33.9-85.5c0.2-0.2,0.3-0.4,0.5-0.6C67.1,20.4,108.4,0,154.5,0			C206.6,0,252.6,26,280.6,65.8z"
          />
          <path
            fill="#dfe9ed"
            d="M83.3,198c15.8,15.7,26.7,52.1,32.7,109.1C49.3,289.9,0,228.8,0,156c0-20.4,3.9-40,11-57.9			C16.5,135.1,38.7,168.7,83.3,198z"
          />
        </g>
        <g> </g>
      </g>
    </svg>
  );
};
