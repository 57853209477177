import { Switch, Route } from "react-router-dom";

//components
import { withMenuLayout } from "common/MenuLayout/MenuLayout";
import { PrivateRoute } from "common/PrivateRoute";
import { LoginPage } from "feat/auth/components/LoginPage";
import { GroupsPage } from "feat/objects/components/Groups/GroupsPage";
import { LampsPage } from "feat/objects/components/Lamps/LampsPage";
import { TimetablePage } from "feat/objects/components/Timetable/TimetablePage";

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <PrivateRoute path="/lamps" component={withMenuLayout(LampsPage)} />
      <PrivateRoute
        path="/timetable"
        component={withMenuLayout(TimetablePage)}
      />

      <PrivateRoute exact path="/" component={withMenuLayout(GroupsPage)} />
    </Switch>
  );
};
