import { notification } from "antd";

const openNotificationWithIcon = ({ message }) => {
  const desc = typeof message === "string" ? message : "Обновите страницу";

  notification["error"]({
    message: "Ошибка",
    description: desc,
  });
};

export const authMiddleware = (store) => (next) => (action) => {
  if (action?.payload?.name === "ApiError") {
    openNotificationWithIcon({
      message: "Внутренняя ошибка сервера",
    });
  }

  return next(action);
};
