/*interface IError {
  name: string;
  code: string;
  message: string;
}*/

class ApiError extends Error {
  constructor(resp: any) {
    super(resp);
    this.name = "ApiError";
    //@ts-ignore
    this.code = resp.status;
    this.message = resp.statusText;
  }
}

export const handleErrors = (resp: any) => {
  throw new ApiError(resp);
};
