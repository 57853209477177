import { useAppDispatch } from "app/hooks";

//componetns
import { Button } from "antd";

//actions
import { lampsSlice } from "feat/objects/reducers/lampsSlice";

//style
import style from "./Lamps.module.css";

const { setEditModalOpen } = lampsSlice.actions;

export const EditGroupsButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const onEditButtonClick = () => {
    dispatch(setEditModalOpen(true));
  };

  return (
    <Button
      type="primary"
      onClick={onEditButtonClick}
      className={style.editButton}
    >
      Группа светильников
    </Button>
  );
};
