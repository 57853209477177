import { Badge } from "antd";

import { TableActions } from "./TableActions";
import { Dimm } from "./Dimm/Dimm";
import { Control } from "./Control";

// types
import { ColumnsType } from "antd/es/table";
import { IGroups } from "types";

export const GroupsTableColumns: ColumnsType<IGroups> = [
  {
    title: "Группа",
    dataIndex: "group_name",
  },
  {
    title: "Светильники",
    dataIndex: "lamp_quantity",
    align: "right",
    render: (lamp_quantity) => (
      <Badge count={lamp_quantity} className="lamps-badge" />
    ),
  },
  {
    title: "Диммирование",
    dataIndex: "curr_percent",
    width: "370px",
    render: (dimm, record) => (
      <Dimm
        dimm={dimm}
        group_id={record.group_id}
        mode={record.mode}
        lamps={record.lamp_quantity}
      />
    ),
  },
  {
    title: "Расписание",
    dataIndex: "mode",
    render: (mode, record) => (
      <Control
        mode={mode}
        group_id={record.group_id}
        schedule={record.schedule_name}
      />
    ),
  },
  {
    title: "Действия",
    dataIndex: "group_id",
    render: (group_id) => <TableActions group_id={group_id} />,
  },
];
