import { useState } from "react";

//componetns
import { Typography } from "antd";
import { AddButton } from "ui/AddButton";
import { AddTimetableForm } from "./Forms/AddTimetableForm";
import { TimetableTable } from "./TimetableTable";

//style
import style from "./Timetable.module.css";

const { Title } = Typography;

export const Timetable: React.FC = () => {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const onAddClick = () => {
    setIsAddFormOpen(true);
  };

  return (
    <>
      <div className={style.title}>
        <Title level={4}>Расписание</Title>

        <AddButton onAddClick={onAddClick} />
      </div>

      <TimetableTable />

      {isAddFormOpen ? (
        <AddTimetableForm
          isAddFormOpen={isAddFormOpen}
          setIsAddFormOpen={setIsAddFormOpen}
        />
      ) : null}
    </>
  );
};
