import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { useFormik, FormikProvider } from "formik";

//components
import { Form, Modal } from "antd";
import { Input, FormItem } from "formik-antd";

//utils
import { layout } from "feat/objects/utils/layout";
import { addTimetableValidationSchema } from "feat/objects/utils/validationSchema";

//actions
import { getScheduleEvents } from "feat/objects/actions";

//types
import { ISchedule } from "types";

//style
import style from "../Timetable.module.css";

interface IProps {
  isEdit: ISchedule | boolean | any;
  setIsEdit: React.Dispatch<React.SetStateAction<ISchedule | boolean>>;
}

export const EditTimetableForm: React.FC<IProps> = ({ isEdit, setIsEdit }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getScheduleEvents(isEdit.schedule_id));
  }, [dispatch, isEdit.schedule_id]);

  const width = window.innerWidth;
  const isMobile = width < 768;

  const [form] = Form.useForm();
  const data = isEdit;

  const formikCtx = useFormik({
    validationSchema: addTimetableValidationSchema,
    validateOnMount: true,
    initialValues: {
      name: data.name,
    },

    onSubmit: async () => {
      setSubmitting(true);
      setIsEdit(false);
      setSubmitting(false);
      resetForm();
    },
  });

  const { handleSubmit, setSubmitting, isValid, isSubmitting, resetForm } =
    formikCtx;

  const handleCancel = () => {
    setIsEdit(false);
    resetForm();
  };

  return (
    <FormikProvider value={formikCtx}>
      <Form
        onFinish={handleSubmit}
        form={form}
        id="edit"
        {...layout}
        layout={isMobile ? "vertical" : "horizontal"}
      >
        <Modal
          title={<b>Настройка расписания</b>}
          visible={!!isEdit}
          okButtonProps={{
            htmlType: "submit",
            form: "edit",
            disabled: !isValid || isSubmitting,
          }}
          onOk={() => form.submit}
          okText="Сохранить"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
          className={style.modal}
          width={"max-content"}
        >
          <FormItem name="name" label="Название" required>
            <Input placeholder="Название" name="name" />
          </FormItem>
        </Modal>
      </Form>
    </FormikProvider>
  );
};
