import { useAppDispatch, useAppSelector } from "app/hooks";

//components
import { Menu, Dropdown, Popconfirm, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

//actions
import { lampsSlice } from "feat/objects/reducers/lampsSlice";
import { testLamp } from "feat/objects/actions";

const { setEditModalOpen } = lampsSlice.actions;

interface IProps {
  lamp_id: number;
}

export const LampsTableActions: React.FC<IProps> = ({ lamp_id }) => {
  const dispatch = useAppDispatch();
  const groupsList = useAppSelector((state) => state.groups.groups);

  const onChangeGroupClick = () => {
    dispatch(setEditModalOpen(lamp_id));
  };

  const onTestLampClick = () => {
    dispatch(testLamp(lamp_id));
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button
          type="link"
          onClick={onChangeGroupClick}
          disabled={!groupsList.length}
        >
          Изменить группу
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Popconfirm
        title="Включить освещение на 3 сек?"
        onConfirm={() => onTestLampClick()}
        okText="Да"
        cancelText="Нет"
      >
        <Button type="link">Тест включение</Button>
      </Popconfirm>

      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link">
          Еще...
          <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
