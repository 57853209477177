import { useState, useEffect } from "react";
import { useAppDispatch } from "app/hooks";

//components
import { Slider, InputNumber } from "antd";

//actions
import { dimmGroup } from "feat/objects/actions";

//utils
import { groupsModeEnum } from "lib/constants";

//type
import { IGroupMode } from "types";

//style
import style from "./Dimm.module.css";
import "./index.css";

interface IProps {
  dimm: number;
  group_id: number;
  mode: IGroupMode;
  lamps: number;
}

export const Dimm: React.FC<IProps> = ({ dimm, group_id, mode, lamps }) => {
  const dispatch = useAppDispatch();

  //Проверяем на смешанность
  const isNoDimm = dimm === -1;

  const [currentValue, setValue] = useState(isNoDimm ? undefined : dimm);

  //блокируем диммирование если нет светильников в группе
  const noLamps = lamps === 0;

  //блокиpуем диммирование если режим авто
  const isDisabled = mode === groupsModeEnum.AUTO;

  useEffect(() => {
    if (noLamps) {
      setValue(0);
    }
  }, [noLamps]);

  const marks = {
    0: "",
    25: "25%",
    50: "50%",
    75: "75%",
    100: "",
  };

  const onChange = (value: number) => {
    setValue(value ? value : 0);
  };

  const sendDimm = (value: number) => {
    dispatch(dimmGroup({ group_id, percent: value ? value : 0 }));
  };

  const sendDimmInput = (e: any) => {
    dispatch(
      dimmGroup({ group_id, percent: e.target.value ? e.target.value : 0 })
    );
  };

  if (isDisabled && !isNoDimm) {
    return <span>{dimm} %</span>;
  }

  if (isDisabled && isNoDimm) {
    return <span className={style.noDimm}>Смешанное</span>;
  }

  return (
    <div className={style.dimm}>
      <>
        <InputNumber
          min={0}
          max={100}
          value={currentValue}
          onChange={onChange}
          onBlur={sendDimmInput}
          className={style.inputNumber}
          disabled={isDisabled || noLamps}
        />
        <span className={style.percent}>%</span>
      </>

      <Slider
        className={style.slider}
        marks={marks}
        value={currentValue}
        onChange={onChange}
        onAfterChange={sendDimm}
        tipFormatter={null}
        step={1}
        disabled={isDisabled || noLamps}
      />

      {
        //@ts-ignore
        currentValue >= 0 ? null : (
          <span className={style.noDimm}>Смешанное</span>
        )
      }
    </div>
  );
};
