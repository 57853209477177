import { Badge } from "antd";
import { LampsTableActions } from "./LampsTableActions";

// types
import { ILamp } from "types";
import { ColumnsType } from "antd/es/table";

export const LampsTableColumns: ColumnsType<ILamp> = [
  {
    title: "Светильник",
    dataIndex: "model",
  },
  {
    title: "Группы",
    dataIndex: "groups",
    align: "right",
    render: (groups) => <Badge count={groups.length} className="lamps-badge" />,
  },
  {
    title: "Действия",
    dataIndex: "lamp_id",
    render: (lamp_id) => <LampsTableActions lamp_id={lamp_id} />,
  },
];
