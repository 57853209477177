import { useAppDispatch, useAppSelector } from "app/hooks";

//components
import { Button, Select } from "antd";

//actions
import { lampsSlice } from "feat/objects/reducers/lampsSlice";

//style
import style from "./LampsFilter.module.css";

const { setFilter } = lampsSlice.actions;
const { Option } = Select;

export const LampsFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.lamps.filter);
  const groups = useAppSelector((state) => state.groups.groups);

  const onCancelClick = () => {
    dispatch(setFilter({ ...filter, group: null, tp: null }));
  };

  return (
    <div className={style.filterBox}>
      <div>
        <span>Группа: </span>

        <Select
          showSearch
          allowClear
          value={filter.group}
          onChange={(value) => dispatch(setFilter({ ...filter, group: value }))}
          optionFilterProp="children"
          placeholder="не выбрано..."
          className={style.groupSelect}
        >
          {groups.map((group) => (
            <Option key={group.group_id} value={group.group_id}>
              {group.group_name}
            </Option>
          ))}
        </Select>
      </div>

      {/* <div className={style.secondBox}>
        <span>ТП: </span>

        <Select
          showSearch
          allowClear
          value={filter.tp}
          onChange={(value) => dispatch(setFilter({ ...filter, tp: value }))}
          optionFilterProp="children"
          placeholder="не выбрано..."
          className={style.groupSelect}
        >
          <Option key="ТП 1" value="ТП 1">
            ТП 1
          </Option>

          <Option key="ТП 2" value="ТП 2">
            ТП 2
          </Option>
        </Select>
      </div> */}

      {filter.group || filter.tp ? (
        <Button onClick={onCancelClick} className={style.cancelButton}>
          Отмена
        </Button>
      ) : null}
    </div>
  );
};
