import { useState } from "react";

//componetns
import { Typography } from "antd";
import { AddButton } from "ui/AddButton";
import { GroupsTable } from "./GroupsTable";
import { AddGroupForm } from "./Forms/AddGroupForm";

//style
import style from "./Groups.module.css";

const { Title } = Typography;

export const Groups: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onAddClick = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div className={style.title}>
        <Title level={4}>Группы</Title>

        <AddButton onAddClick={onAddClick} />
      </div>

      <GroupsTable />

      <AddGroupForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};
