import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFormik, FormikProvider } from "formik";

//components
import { Form, Modal, Space } from "antd";
import { Input, FormItem, Radio } from "formik-antd";

//utils
import { layout } from "feat/objects/utils/layout";
import { editGroupValidationSchema } from "feat/objects/utils/validationSchema";

import { getSchedules, editGroup } from "feat/objects/actions";

//style
import style from "../Groups.module.css";

interface IProps {
  editMode: string;
  isEdit: number | boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<number | boolean>>;
}

export const EditGroupForm: React.FC<IProps> = ({
  isEdit,
  setIsEdit,
  editMode,
}) => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groups.groups);
  const currentGroup = groups.find((group) => group.group_id === isEdit);
  const schedules = useAppSelector((state) => state.schedule.schedules);

  const width = window.innerWidth;
  const isMobile = width < 768;

  useEffect(() => {
    dispatch(getSchedules());
  }, [dispatch]);

  const [form] = Form.useForm();

  const formikCtx = useFormik({
    validationSchema: editGroupValidationSchema,
    validateOnMount: true,
    initialValues: {
      group_name: currentGroup?.group_name ?? "",
      schedule_id: currentGroup?.schedule_id ?? 0,
    },

    onSubmit: async ({ group_name, schedule_id }) => {
      const schedule_name =
        schedules.find((sh) => sh.schedule_id === schedule_id)?.schedule_name ??
        "";

      setSubmitting(true);
      dispatch(
        editGroup({ group_name, schedule_id, group_id: isEdit, schedule_name })
      );
      setIsEdit(false);
      setSubmitting(false);
      resetForm();
    },
  });

  const {
    handleSubmit,
    setSubmitting,
    isValid,
    isSubmitting,
    resetForm,
    values,
  } = formikCtx;

  const handleCancel = () => {
    setIsEdit(false);
    resetForm();
  };

  const isAllowToEdit = editMode !== "name" && values.schedule_id === 0;

  return (
    <FormikProvider value={formikCtx}>
      <Form
        onFinish={handleSubmit}
        form={form}
        id="edit"
        {...layout}
        layout={isMobile ? "vertical" : "horizontal"}
      >
        <Modal
          title={<b>Настройка группы светильников</b>}
          visible={!!isEdit}
          okButtonProps={{
            htmlType: "submit",
            form: "edit",
            disabled: !isValid || isSubmitting || isAllowToEdit,
          }}
          onOk={() => form.submit}
          okText="Сохранить"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
        >
          {editMode === "name" ? (
            <FormItem name="group_name" label="Название" required>
              <Input placeholder="Название" name="group_name" />
            </FormItem>
          ) : (
            <FormItem name="schedule_id" label="Расписание" required>
              <Radio.Group
                name="schedule_id"
                value={values.schedule_id}
                className={style.radioGroup}
              >
                <Space direction="vertical">
                  {schedules.map((tt) => (
                    <Radio
                      name="schedule_id"
                      key={tt.schedule_id}
                      value={tt.schedule_id}
                    >
                      {tt.schedule_name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </FormItem>
          )}
        </Modal>
      </Form>
    </FormikProvider>
  );
};
