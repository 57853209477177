import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFormik, FormikProvider } from "formik";

//components
import { Form, Modal, Space } from "antd";
import { FormItem, Radio } from "formik-antd";

//actions
import { deleteGroup } from "feat/objects/actions";

//utils
import { layout } from "feat/objects/utils/layout";

//style
import style from "../Groups.module.css";

interface IProps {
  isDel: number | boolean;
  setIsDel: React.Dispatch<React.SetStateAction<number | boolean>>;
}

export const DelGroupForm: React.FC<IProps> = ({ isDel, setIsDel }) => {
  const dispatch = useAppDispatch();
  const groupsList = useAppSelector((state) => state.groups.groups);

  //убираем текущую группу со списка
  const groups = groupsList.filter((group) => group.group_id !== isDel);

  const width = window.innerWidth;
  const isMobile = width < 768;

  const [form] = Form.useForm();

  const formikCtx = useFormik({
    validateOnMount: true,
    initialValues: {
      group: "",
    },

    onSubmit: async ({ group }) => {
      setSubmitting(true);
      dispatch(
        deleteGroup({ group_id: isDel, new_group: parseInt(group, 10) })
      );
      setIsDel(false);
      setSubmitting(false);
      resetForm();
    },
  });

  const { handleSubmit, setSubmitting, isSubmitting, resetForm, values } =
    formikCtx;

  const handleCancel = () => {
    setIsDel(false);
    resetForm();
  };

  //Проверяем что это последняя группа
  const lastGroup = groups.length === 0;

  return (
    <FormikProvider value={formikCtx}>
      <Form
        onFinish={handleSubmit}
        form={form}
        id="del"
        {...layout}
        layout={isMobile ? "vertical" : "horizontal"}
      >
        <Modal
          title={<b>Удаление группы светильников</b>}
          visible={!!isDel}
          okButtonProps={{
            htmlType: "submit",
            form: "del",
            disabled: isSubmitting,
          }}
          onOk={() => form.submit}
          okText="Удалить"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
        >
          <span>Выберите группу, в которую перенести светильники:</span>

          {!lastGroup ? (
            <FormItem name="group" label="Группа" className={style.delText}>
              <Radio.Group
                name="group"
                value={values.group}
                className={style.radioGroup}
              >
                <Space direction="vertical">
                  {groups.map((group) => (
                    <Radio
                      name="group"
                      key={group.group_id}
                      value={group.group_id}
                    >
                      {group.group_name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </FormItem>
          ) : null}
        </Modal>
      </Form>
    </FormikProvider>
  );
};
