import { HelmetProvider } from "react-helmet-async";

import "antd/dist/antd.css";

import { Routes } from "./Routes";
import { AppLayout } from "common/AppLayout";

export const App: React.FC = () => {
  return (
    <AppLayout>
      <HelmetProvider>
        <Routes />
      </HelmetProvider>
    </AppLayout>
  );
};
