import { combineReducers, configureStore, AnyAction } from "@reduxjs/toolkit";
import api from "lib/api";

//slices
import { authSlice } from "feat/auth/reducers";
import { groupsSlice } from "feat/objects/reducers/groupsSlice";
import { lampsSlice } from "feat/objects/reducers/lampsSlice";
import { scheduleSlice } from "feat/objects/reducers/scheduleSlice";

//middlewares
import { authMiddleware } from "lib/middlewares";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  groups: groupsSlice.reducer,
  lamps: lampsSlice.reducer,
  schedule: scheduleSlice.reducer,
});

const resettableRootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout") {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
    }).concat(authMiddleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
