import { useState } from "react";
import { useAppDispatch } from "app/hooks";

//components
import { Menu, Dropdown, Popconfirm, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { EditGroupForm } from "./Forms/EditGroupForm";
import { DelGroupForm } from "./Forms/DelGroupForm";

//actions
import { testGroup } from "feat/objects/actions";

//style
import style from "./Groups.module.css";

interface iProps {
  group_id: number;
}

export const TableActions: React.FC<iProps> = ({ group_id }) => {
  const [isEdit, setIsEdit] = useState<number | boolean>(false);
  const [isDel, setIsDel] = useState<number | boolean>(false);
  const [editMode, setEditMode] = useState("name");

  const dispatch = useAppDispatch();

  const onEditClick = () => {
    setEditMode("name");
    setIsEdit(group_id);
  };

  const onEditTimetableClick = () => {
    setEditMode("timetable");
    setIsEdit(group_id);
  };

  const onDeleteButtonClick = () => {
    setIsDel(group_id);
  };

  const onTestLampClick = () => {
    dispatch(testGroup(group_id));
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button type="link" onClick={onEditClick}>
          Редактирование
        </Button>
      </Menu.Item>

      <Menu.Item key="2">
        <Button type="link" onClick={onEditTimetableClick}>
          Расписание
        </Button>
      </Menu.Item>

      <Menu.Item key="3">
        <Button
          type="link"
          onClick={onDeleteButtonClick}
          className={style.delButton}
        >
          Удалить
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Popconfirm
        title="Включить освещение на 3 сек?"
        onConfirm={() => onTestLampClick()}
        okText="Да"
        cancelText="Нет"
      >
        <Button type="link">Тест включение</Button>
      </Popconfirm>

      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link">
          Еще...
          <DownOutlined />
        </Button>
      </Dropdown>

      {isEdit ? (
        <EditGroupForm
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editMode={editMode}
        />
      ) : null}
      {isDel ? <DelGroupForm isDel={isDel} setIsDel={setIsDel} /> : null}
    </>
  );
};
