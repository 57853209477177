import { useState } from "react";
import { useAppDispatch } from "app/hooks";

//components
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { DelButton } from "ui/DelButton";
import { TimetableSettingsForm } from "./Forms/TimetableSettingsForm";
import { EditTimetableForm } from "./Forms/EditTimetableForm";

//types
import { ISchedule } from "types";

//actions
import { deleteSchedule, getScheduleEvents } from "feat/objects/actions";

interface IProps {
  timetable: ISchedule;
}

export const TimetableTableActions: React.FC<IProps> = ({ timetable }) => {
  const [isEdit, setIsEdit] = useState<ISchedule | boolean>(false);
  const [openSettings, setOpenSettings] = useState<ISchedule | boolean>(false);
  const dispatch = useAppDispatch();

  const onEditClick = () => {
    setIsEdit(timetable);
  };

  const onEditEventClick = async () => {
    await dispatch(getScheduleEvents(timetable.schedule_id));
    setOpenSettings(timetable);
  };

  const onDeleteButtonClick = () => {
    dispatch(deleteSchedule(timetable.schedule_id));
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button type="link" onClick={onEditClick}>
          Редактировать
        </Button>
      </Menu.Item>

      <Menu.Item key="2">
        <DelButton onDeleteButtonClick={onDeleteButtonClick} disabled={false} />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Button type="link" onClick={onEditEventClick}>
        Настройка расписания
      </Button>

      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link">
          Еще...
          <DownOutlined />
        </Button>
      </Dropdown>

      {isEdit ? (
        <EditTimetableForm isEdit={isEdit} setIsEdit={setIsEdit} />
      ) : null}

      {openSettings ? (
        <TimetableSettingsForm
          isEdit={openSettings}
          setIsEdit={setOpenSettings}
        />
      ) : null}
    </>
  );
};
