import { useAppDispatch } from "app/hooks";
import { useFormik, FormikProvider } from "formik";

//components
import { Form, Modal } from "antd";
import { Input, FormItem } from "formik-antd";

//utils
import { layout } from "feat/objects/utils/layout";
import { addGroupValidationSchema } from "feat/objects/utils/validationSchema";

//actions
import { createGroup } from "feat/objects/actions";

interface IProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddGroupForm: React.FC<IProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const dispatch = useAppDispatch();

  const width = window.innerWidth;
  const isMobile = width < 768;

  const [form] = Form.useForm();

  const formikCtx = useFormik({
    validationSchema: addGroupValidationSchema,
    validateOnMount: true,
    initialValues: {
      groupName: "",
    },

    onSubmit: async ({ groupName }) => {
      setSubmitting(true);
      dispatch(createGroup(groupName));
      setIsModalVisible(false);
      setSubmitting(false);
      resetForm();
    },
  });

  const { handleSubmit, setSubmitting, isValid, isSubmitting, resetForm } =
    formikCtx;

  const handleCancel = () => {
    setIsModalVisible(false);
    resetForm();
  };

  return (
    <FormikProvider value={formikCtx}>
      <Form
        onFinish={handleSubmit}
        form={form}
        id="add"
        {...layout}
        layout={isMobile ? "vertical" : "horizontal"}
      >
        <Modal
          title={<b>Создание группы светильников</b>}
          visible={isModalVisible}
          okButtonProps={{
            htmlType: "submit",
            form: "add",
            disabled: !isValid || isSubmitting,
          }}
          onOk={() => form.submit}
          okText="Создать"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
        >
          <FormItem name="groupName" label="Название" required>
            <Input placeholder="Название" name="groupName" />
          </FormItem>
        </Modal>
      </Form>
    </FormikProvider>
  );
};
