import { createSlice, current } from "@reduxjs/toolkit";

//actions
import { getLamps, testLamp, editLampGroup } from "../actions";

//types
import { ILamp, IError } from "types";

interface IInitialState {
  lamps: ILamp[];
  loading: boolean;
  loadingTable: boolean;
  error: IError;
  filter: IFilter;
  isEditModalOpen: number | boolean;
  selectedLamps: object;
}

interface IFilter {
  sorter: "all" | "withoutGroups";
  group: any;
  tp: any;
}

const initialState: IInitialState = {
  lamps: [],
  filter: {
    sorter: "all",
    group: null,
    tp: null,
  },
  isEditModalOpen: false,
  selectedLamps: {},
  loading: false,
  loadingTable: false,
  error: null,
};

export const lampsSlice = createSlice({
  name: "lamps",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setEditModalOpen: (state, action) => {
      state.isEditModalOpen = action.payload;
    },
    toggleLamps: (state, action) => {
      const lampId = action.payload;
      const currentSelectedLamps = current(state.selectedLamps);
      // @ts-ignore
      const isSelected = currentSelectedLamps[lampId];

      if (!isSelected) {
        // @ts-ignore
        state.selectedLamps[lampId] = action.payload;
      } else {
        // @ts-ignore
        delete state.selectedLamps[lampId];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLamps.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLamps.fulfilled, (state, action) => {
      state.loading = false;
      state.lamps = action.payload;
    });
    builder.addCase(getLamps.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //тестирование светильников
    builder.addCase(testLamp.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(testLamp.fulfilled, (state) => {
      state.loadingTable = false;
    });
    builder.addCase(testLamp.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //тестирование светильников
    builder.addCase(editLampGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(editLampGroup.fulfilled, (state) => {
      state.loadingTable = false;
    });
    builder.addCase(editLampGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
