import auth from "feat/auth/api";
import objects from "feat/objects/api";

const api = {
  auth,
  objects,
};

export default api;
export type IApi = typeof api;

export const request = async (fetchUrl: string, method: string, body?: any) => {
  const url = `/api${fetchUrl}`;

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body,
  };

  return fetch(url, options);
};
