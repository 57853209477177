import { useAppDispatch, useAppSelector } from "app/hooks";

//component
import { Menu, Badge } from "antd";

//actions
import { lampsSlice } from "feat/objects/reducers/lampsSlice";

//utils
import { lampsSorterEnum } from "lib/constants";

//selectors
import { selectCounter } from "feat/objects/selectors/selectFilteredLamps";

//style
import "feat/objects/style/index.css";
import style from "./LampsFilter.module.css";

const { Item } = Menu;
const { setFilter } = lampsSlice.actions;

export const LampsSorter: React.FC = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.lamps.filter);
  const counter = useAppSelector(selectCounter);

  const onSorterClick = ({ key }: any) =>
    dispatch(setFilter({ ...filter, sorter: key }));

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[filter.sorter]}
      onClick={onSorterClick}
      className={style.sorter}
    >
      <Item key={lampsSorterEnum.ALL} disabled={!counter.all}>
        Все
        <Badge count={counter.all} className="sorter-badge" />
      </Item>

      <Item key={lampsSorterEnum.WHTG} disabled={!counter.wthGroup}>
        Без групп
        <Badge count={counter.wthGroup} className="sorter-badge" />
      </Item>
    </Menu>
  );
};
