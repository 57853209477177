import { createSlice } from "@reduxjs/toolkit";

//actions
import { login } from "./actions";

//types
import { IError } from "types";

interface IInitialState {
  user: string | null;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = { user: null, loading: false, error: null };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
