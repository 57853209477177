import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

interface IProps {
  onAddClick(): void;
}

export const AddButton: React.FC<IProps> = ({ onAddClick }) => {
  return (
    <Button type="primary" icon={<PlusOutlined />} onClick={onAddClick}>
      {" "}
      Добавить
    </Button>
  );
};
