import { useAppSelector } from "app/hooks";

// componetns
import { Table, Empty } from "antd";
import { LampsTableColumns } from "./LampsTableColumns";

// selectors
import { selectFilteredLamps } from "feat/objects/selectors/selectFilteredLamps";

// types
import { ILamp } from "types";

// style
import style from "./Lamps.module.css";

export const LampsTable: React.FC = () => {
  const loading = useAppSelector((state) => state.lamps.loadingTable);
  const data: ILamp[] = useAppSelector(selectFilteredLamps);

  if (data.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Table<ILamp>
      className={style.table}
      columns={LampsTableColumns}
      dataSource={data}
      pagination={false}
      loading={loading}
      rowKey="lamp_id"
      scroll={{ x: "max-content" }}
    />
  );
};
