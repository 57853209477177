import { useAppDispatch } from "app/hooks";
import { useFormik, FormikProvider } from "formik";

//components
import { Form, Modal } from "antd";
import { Input, FormItem } from "formik-antd";

//actions
import { createSchedule } from "feat/objects/actions";

//utils
import { layout } from "feat/objects/utils/layout";
import { addTimetableValidationSchema } from "feat/objects/utils/validationSchema";

interface IProps {
  isAddFormOpen: boolean;
  setIsAddFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddTimetableForm: React.FC<IProps> = ({
  isAddFormOpen,
  setIsAddFormOpen,
}) => {
  const dispatch = useAppDispatch();

  const width = window.innerWidth;
  const isMobile = width < 768;

  const [form] = Form.useForm();

  const formikCtx = useFormik({
    validationSchema: addTimetableValidationSchema,
    validateOnMount: true,
    initialValues: {
      name: "",
    },

    onSubmit: async ({ name }) => {
      setSubmitting(true);
      dispatch(createSchedule(name));
      setIsAddFormOpen(false);
      setSubmitting(false);
      resetForm();
    },
  });

  const { handleSubmit, setSubmitting, isValid, isSubmitting, resetForm } =
    formikCtx;

  const handleCancel = () => {
    setIsAddFormOpen(false);
    resetForm();
  };

  return (
    <FormikProvider value={formikCtx}>
      <Form
        onFinish={handleSubmit}
        form={form}
        id="add"
        {...layout}
        layout={isMobile ? "vertical" : "horizontal"}
      >
        <Modal
          title={<b>Создание расписания</b>}
          visible={isAddFormOpen}
          okButtonProps={{
            htmlType: "submit",
            form: "add",
            disabled: !isValid || isSubmitting,
          }}
          onOk={() => form.submit}
          okText="Создать"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
        >
          <FormItem name="name" label="Название" required>
            <Input placeholder="Название" name="name" />
          </FormItem>
        </Modal>
      </Form>
    </FormikProvider>
  );
};
