import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFormik, FormikProvider } from "formik";

//components
import { Form, Modal, Space } from "antd";
import { FormItem, Checkbox } from "formik-antd";

//actions
import { lampsSlice } from "feat/objects/reducers/lampsSlice";
import { editLampGroup } from "feat/objects/actions";

//utils
import { layout } from "feat/objects/utils/layout";

//style
import style from "./Lamps.module.css";

const { setEditModalOpen } = lampsSlice.actions;

export const EditGroupModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isEdit = useAppSelector((state) => state.lamps.isEditModalOpen);
  const groups = useAppSelector((state) => state.groups.groups);
  const lamps = useAppSelector((state) => state.lamps.lamps);
  const currentLamp = lamps.find((lamp) => lamp.lamp_id === isEdit);
  const currentLampGroupsIds =
    currentLamp?.groups.map((group) => group.group_id) ?? [];

  const width = window.innerWidth;
  const isMobile = width < 768;

  const [form] = Form.useForm();

  const formikCtx = useFormik({
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      groups: currentLampGroupsIds,
    },

    onSubmit: async ({ groups }) => {
      setSubmitting(true);
      dispatch(editLampGroup({ lamp_id: isEdit, groups }));
      dispatch(setEditModalOpen(false));
      setSubmitting(false);
      resetForm();
    },
  });

  const { handleSubmit, setSubmitting, isSubmitting, resetForm, values } =
    formikCtx;

  const isValid = values.groups.length;

  const handleCancel = () => {
    dispatch(setEditModalOpen(false));
    resetForm();
  };

  return (
    <FormikProvider value={formikCtx}>
      <Form
        onFinish={handleSubmit}
        form={form}
        id="edit"
        {...layout}
        layout={isMobile ? "vertical" : "horizontal"}
      >
        <Modal
          title={<b>Редактирование группы светильников</b>}
          visible={!!isEdit}
          okButtonProps={{
            htmlType: "submit",
            form: "edit",
            disabled: !isValid || isSubmitting,
          }}
          onOk={() => form.submit}
          okText="Сохранить"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
        >
          <span>Выберите группы светильников.</span>

          <FormItem name="groups" label="Группа" className={style.groups}>
            <Checkbox.Group
              name="groups"
              value={values.groups}
              className={style.checkboxGroup}
            >
              <Space direction="vertical">
                {groups.map((group) => (
                  <Checkbox
                    name="group"
                    key={group.group_id}
                    value={group.group_id}
                  >
                    {group.group_name}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </FormItem>
        </Modal>
      </Form>
    </FormikProvider>
  );
};
