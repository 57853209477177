import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Helmet } from "react-helmet-async";

//components
import { Loading } from "common/Loading";
import { ContentLayout } from "common/ContentLayout";
import { Timetable } from "./Timetable";

//actions
import { getSchedules } from "feat/objects/actions";

export const TimetablePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.schedule.loading);

  useEffect(() => {
    dispatch(getSchedules());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Расписание</title>
      </Helmet>

      <ContentLayout>
        <Timetable />
      </ContentLayout>
    </>
  );
};
