import { useAppSelector } from "app/hooks";

//componetns
import { Table } from "antd";
import { TimetableTableColumns } from "./TimetableTableColumns";

//types
import { ISchedule } from "types";

//style
import style from "./Timetable.module.css";

export const TimetableTable: React.FC = () => {
  const data: ISchedule[] = useAppSelector((state) => state.schedule.schedules);
  const loading = useAppSelector((state) => state.schedule.loadingTable);

  return (
    <Table<ISchedule>
      className={style.table}
      columns={TimetableTableColumns}
      dataSource={data}
      rowKey="schedule_id"
      loading={loading}
      pagination={false}
      scroll={{ x: "max-content" }}
    />
  );
};
