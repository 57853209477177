import { useAppSelector } from "app/hooks";

//componetns
import { Typography } from "antd";
import { LampsTable } from "./LampsTable";
import { LampsSorter } from "./Sorter/LampsSorter";
import { LampsFilter } from "./Sorter/LampsFilter";
import { EditGroupModal } from "./EditGroupModal";
import { EditGroupsButton } from "./EditGroupsButton";

const { Title } = Typography;

export const Lamps: React.FC = () => {
  const selectedLamps = useAppSelector((state) => state.lamps.selectedLamps);
  const isEdit = useAppSelector((state) => state.lamps.isEditModalOpen);
  const hasLamps = Object.keys(selectedLamps).length;

  return (
    <>
      <Title level={4}>Светильники</Title>

      <LampsFilter />

      {!hasLamps ? <LampsSorter /> : <EditGroupsButton />}

      <LampsTable />

      {isEdit ? <EditGroupModal /> : null}
    </>
  );
};
