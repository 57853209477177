import { string, object } from "yup";

export const addGroupValidationSchema = object({
  groupName: string().min(2).max(20).required(),
});

export const editGroupValidationSchema = object({
  group_name: string().min(2).max(20).required(),
});

export const addTimetableValidationSchema = object({
  name: string().min(2).max(20).required(),
});
