//componetns
import { Table, Button } from "antd";
import { Select, Slider } from "formik-antd";

//style
import style from "../Timetable.module.css";

//types
import { ColumnsType } from "antd/es/table";
import { IEvent } from "types";

const { Option } = Select;

interface IProps {
  arrays: any;
  events: IEvent[];
  intValidRule: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export const SettingsFormFields: React.FC<IProps> = ({
  arrays,
  events,
  intValidRule,
  setFieldValue,
}) => {
  const start = arrays.startInt ?? [];
  const finish = arrays.finishInt ?? [];

  //отметки на селекте диммирования
  const marks = {
    0: "",
    25: "25%",
    50: "50%",
    75: "75%",
    100: "",
  };

  //шаблон ивента
  const initInt = {
    start_at: "",
    finish_at: "",
    percent: 0,
    active: true,
    schedule_event_id: 0,
  };

  const onSelectFinish = (option: string, index: number) => {
    if (index !== events.length - 1) {
      setFieldValue(`events[${index + 1}].start_at`, option);
    }
  };

  /////////////ПРАВИЛА/////////////////////////

  const getStart = (index: number, item: string) => {
    //в первой строке начинаем с нуля
    if (index === 0 && start.indexOf(item) !== 0) {
      return true;
    }

    if (
      index !== 0 &&
      //блокируем все что менише чем финиш на предыдущей строке
      start.indexOf(item) < start.indexOf(events[index - 1].finish_at)
    ) {
      return true;
    }

    if (
      index !== 0 &&
      //на предыдущей строчке поставили в старте последнее значение
      (start.indexOf(events[index - 1].start_at) === start.length - 1 ||
        //на предыдущей строчке поставили в финише последнее значение
        finish.indexOf(events[index - 1].finish_at) === finish.length - 1)
    ) {
      return true;
    }

    return false;
  };

  const getFinish = (index: number, item: string) => {
    if (
      index !== 0 &&
      //блокируем все что меньше или равно чем старт на этой строке
      finish.indexOf(item) <= finish.indexOf(events[index].start_at)
    ) {
      return true;
    }

    if (
      index !== 0 &&
      //блокируем если на предыдущей строке поставили последнее время в финиш
      finish.indexOf(events[index - 1].finish_at) === finish.length - 1
    ) {
      return true;
    }

    return false;
  };

  const getAddRule = () => {
    //блокируем кнопку добавить если на последней строке последнее время
    if (
      events.length &&
      finish.indexOf(events[events.length - 1].finish_at) === finish.length - 1
    ) {
      return true;
    }

    return intValidRule;
  };

  /////////////ПРАВИЛА/////////////////////////

  const columns: ColumnsType<IEvent> = [
    {
      title: "#",
      dataIndex: "index",
      render: (_, record) => events.indexOf(record) + 1,
    },
    {
      title: "Интервал",
      dataIndex: "interval",
      render: (_, record, index) => (
        <>
          <Select name={`events[${index}].start_at`} className={style.select}>
            {start.map((i: string) => (
              <Option value={i} disabled={getStart(index, i)} key={i}>
                {i}
              </Option>
            ))}
          </Select>

          <span> - </span>

          <Select
            name={`events[${index}].finish_at`}
            className={style.select}
            onSelect={(option) => onSelectFinish(option, index)}
          >
            {finish.map((i: string) => (
              <Option value={i} disabled={getFinish(index, i)} key={i}>
                {i}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: "Диммирование",
      dataIndex: "percent",
      width: "350px",
      render: (_, __, index) => (
        <Slider
          name={`events[${index}].percent`}
          className={style.slider}
          marks={marks}
          tipFormatter={null}
          step={1}
        />
      ),
    },
    {
      dataIndex: "schedule_event_id",
      render: (schedule_event_id) => (
        <Button
          type="link"
          danger
          onClick={() => onDeleteButtonClick(schedule_event_id)}
        >
          Удалить
        </Button>
      ),
    },
  ];

  const onDeleteButtonClick = (schedule_event_id: number) => {
    setFieldValue(
      "events",
      events.filter((ev) => ev.schedule_event_id !== schedule_event_id)
    );
  };

  const onAddEventClick = () => {
    setFieldValue("events", [
      ...events,
      { ...initInt, schedule_event_id: getRandomInt(0, 100) },
    ]);
  };

  return (
    <>
      <Table<IEvent>
        columns={columns}
        dataSource={events}
        pagination={false}
        scroll={{ x: "max-content" }}
        rowKey={(record) => events.indexOf(record)}
      />

      <Button
        type="default"
        className={style.addButton}
        onClick={onAddEventClick}
        disabled={getAddRule()}
      >
        Добавить...
      </Button>
    </>
  );
};
