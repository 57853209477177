import { useAppSelector } from "app/hooks";

//componetns
import { Table } from "antd";
import { GroupsTableColumns } from "./GroupsTableColumns";
import { GroupsCollapse } from "./Mobile/GroupsCollapse";

//style
import style from "./Groups.module.css";

//types
import { IGroups } from "types";

export const GroupsTable: React.FC = () => {
  const groupsList = useAppSelector((state) => state.groups.groups);
  const loading = useAppSelector((state) => state.groups.loadingTable);

  return (
    <>
      <GroupsCollapse groupsList={groupsList} />

      <Table<IGroups>
        className={style.table}
        columns={GroupsTableColumns}
        dataSource={groupsList}
        pagination={false}
        loading={loading}
        rowKey="group_id"
      />
    </>
  );
};
