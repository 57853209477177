import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

//actions
import { getSchedules, deleteSchedule, getScheduleEvents } from "../actions";

//types
import { ISchedule, IError, IEvent } from "types";

interface IInitialState {
  schedules: ISchedule[];
  events: IEvent[];
  loading: boolean;
  loadingTable: boolean;
  error: IError;
}

const initialState: IInitialState = {
  schedules: [],
  events: [],
  loading: false,
  loadingTable: false,
  error: null,
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Получение списка расписаний
    builder.addCase(getSchedules.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSchedules.fulfilled, (state, action) => {
      state.loading = false;
      state.schedules = action.payload.sort((a, b) =>
        a.schedule_name.localeCompare(b.schedule_name)
      );
    });
    builder.addCase(getSchedules.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //удаление расписания
    builder.addCase(deleteSchedule.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(deleteSchedule.fulfilled, (state, action) => {
      state.loadingTable = false;
      state.schedules = current(state.schedules).filter(
        (sh) => sh.schedule_id !== action.payload
      );
    });
    builder.addCase(deleteSchedule.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //получаем ивенты расписания
    builder.addCase(getScheduleEvents.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(getScheduleEvents.fulfilled, (state, action) => {
      state.loadingTable = false;
      state.events = action.payload.map((item: IEvent) => ({
        ...item,
        start_at: moment(item.start_at, "HH:mm").format("HH:mm"),
        finish_at: moment(item.finish_at, "HH:mm").format("HH:mm"),
      }));
    });
    builder.addCase(getScheduleEvents.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
