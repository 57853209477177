//components
import { Popconfirm, Button } from "antd";

interface IProps {
  disabled: boolean;
  onDeleteButtonClick(): void;
}

export const DelButton: React.FC<IProps> = ({
  disabled,
  onDeleteButtonClick,
}) => {
  return (
    <Popconfirm
      title="Вы действительно хотите удалить?"
      disabled={disabled}
      onConfirm={() => onDeleteButtonClick()}
      okText="Да"
      cancelText="Нет"
    >
      <Button danger type="link" disabled={disabled}>
        Удалить
      </Button>
    </Popconfirm>
  );
};
