import { LoginForm } from "./LoginForm";
//components
import { Layout } from "antd";
import { AppLogo } from "common/AppLogo/AppLogo";

//style
import style from "common/MenuLayout/MenuLayout.module.css";

const { Header, Footer } = Layout;

export const LoginPage: React.FC = () => {
  return (
    <Layout className={style.layout}>
      <Header className={style.header}>
        <div className={style.logoBox}>
          <AppLogo />
          <h1 className={style.title}>Ульяновск</h1>
        </div>
      </Header>

      <LoginForm />

      <Footer className={style.footer}>
        ©uSpherum. Система управления наружным освещением
      </Footer>
    </Layout>
  );
};
