import { createSlice, current } from "@reduxjs/toolkit";

//actions
import {
  getGroups,
  dimmGroup,
  testGroup,
  manualGroup,
  autoGroup,
  editGroup,
  deleteGroup,
} from "../actions";

//types
import { IGroups, IError } from "types";

interface IInitialState {
  groups: IGroups[];
  loading: boolean;
  loadingTable: boolean;
  error: IError;
}

const initialState: IInitialState = {
  groups: [],
  loading: false,
  loadingTable: false,
  error: null,
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Получение списка групп
    builder.addCase(getGroups.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.groups = action.payload.sort((a, b) =>
        a.group_name.localeCompare(b.group_name)
      );
    });
    builder.addCase(getGroups.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //тестирование группы светильников
    builder.addCase(testGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(testGroup.fulfilled, (state) => {
      state.loadingTable = false;
    });
    builder.addCase(testGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //диммировние группы светильников
    builder.addCase(dimmGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(dimmGroup.fulfilled, (state) => {
      state.loadingTable = false;
    });
    builder.addCase(dimmGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //перевод в ручной режим группы светильников
    builder.addCase(manualGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(manualGroup.fulfilled, (state, action) => {
      state.loadingTable = false;
      state.groups = current(state.groups).map((group) =>
        group.group_id === action.payload ? { ...group, mode: "manual" } : group
      );
    });
    builder.addCase(manualGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //перевод в авто режим группы светильников
    builder.addCase(autoGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(autoGroup.fulfilled, (state, action) => {
      state.loadingTable = false;
      state.groups = current(state.groups).map((group) =>
        group.group_id === action.payload ? { ...group, mode: "auto" } : group
      );
    });
    builder.addCase(autoGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //редактирование группы светильников
    builder.addCase(editGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(editGroup.fulfilled, (state, action) => {
      state.loadingTable = false;
      state.groups = current(state.groups).map((group) =>
        group.group_id === action.payload.group_id
          ? {
              ...group,
              group_name: action.payload.group_name,
              schedule_name: action.payload.schedule_name,
              schedule_id: action.payload.schedule_id,
            }
          : group
      );
    });
    builder.addCase(editGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //удаление группы светильников
    builder.addCase(deleteGroup.pending, (state) => {
      state.loadingTable = true;
      state.error = null;
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.loadingTable = false;
    });
    builder.addCase(deleteGroup.rejected, (state) => {
      state.loadingTable = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
