import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFormik, FormikProvider } from "formik";
import { object, array } from "yup";
import moment from "moment";

//components
import { Form, Modal, Typography, notification } from "antd";
import { TimePicker, FormItem } from "formik-antd";
import { SettingsFormFields } from "./SettingsFormFields";

//actions
import { editSchedule } from "feat/objects/actions";

//types
import { ISchedule } from "types";

//style
import style from "../Timetable.module.css";

const { Text } = Typography;

interface IProps {
  isEdit: ISchedule | boolean | any;
  setIsEdit: React.Dispatch<React.SetStateAction<ISchedule | boolean>>;
}

export const TimetableSettingsForm: React.FC<IProps> = ({
  isEdit,
  setIsEdit,
}) => {
  const dispatch = useAppDispatch();
  const events = useAppSelector((state) => state.schedule.events);

  const [form] = Form.useForm();

  //Рассчитываем период, если есть.
  //Старт - начало первого ивента
  //Финиш - конец последнего ивента
  const start = events.length ? moment(events[0].start_at, "HH:mm") : null;
  const finish = events.length
    ? moment(events[events.length - 1].finish_at, "HH:mm")
    : null;

  const validationSchema = object({
    events: array().test("events", "Поле обязательно", function test() {
      const events = this.parent.events;
      const finish = this.parent.arrays.finishInt;
      const start = this.parent.arrays.startInt;

      if (
        events.length &&
        //проверяем чтоб в конце был последнее время
        finish.indexOf(events[events.length - 1].finish_at) ===
          finish.length - 1 &&
        //проверяем чтоб у первого ивента в начале было первое время
        start.indexOf(events[0].start_at) === 0
      ) {
        return true;
      }

      return false;
    }),
  });

  const formikCtx = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: {
      start: start,
      finish: finish,
      interval: "",
      arrays: { startInt: [], finishInt: [] },
      events,
      intValidRule: true,
    },

    onSubmit: async ({ events }) => {
      setSubmitting(true);
      const body = { schedule_id: isEdit.schedule_id, events };

      dispatch(editSchedule(body));
      //console.log();
      setIsEdit(false);
      setSubmitting(false);
      resetForm();
    },
  });

  const {
    handleSubmit,
    setSubmitting,
    isSubmitting,
    resetForm,
    setFieldValue,
    isValid,
    values,
  } = formikCtx;

  const handleCancel = () => {
    setIsEdit(false);
    resetForm();
  };

  const intervalChecking = (duration: moment.Duration) => {
    if (moment.duration(duration).asHours() < 2) {
      setFieldValue("intValidRule", true);
      notification["error"]({
        message: `Расписание не может быть меньше 2-х часов`,
      });
    } else if (moment.duration(duration).asHours() > 24) {
      setFieldValue("intValidRule", true);
      notification["error"]({
        message: `Расписание не может превышать 24 часа`,
      });
    } else {
      setFieldValue("intValidRule", false);
    }
  };

  const prepareIntervals = () => {
    let value = {
      interval: values.interval,
      startTime: moment(values.start).format("HH:mm"),
      endTime: moment(values.finish).format("HH:mm"),
    };

    let counter = 0;

    const inputDataFormat = "HH:mm";
    const outputFormat = "HH:mm";

    const tmp = moment(value.interval, inputDataFormat);
    //@ts-ignore
    const dif = tmp - moment().startOf("day");

    const isAfter = value.startTime >= value.endTime;

    const startIntervalTime = moment(value.startTime, inputDataFormat);
    const endIntervalTime = moment(value.startTime, inputDataFormat).add(
      dif,
      "ms"
    );

    const finishTime = isAfter
      ? moment(value.endTime, inputDataFormat).add(1, "days")
      : moment(value.endTime, inputDataFormat);

    const startInt = [];
    const finishInt = [];

    while (startIntervalTime < finishTime && counter < 16) {
      counter = counter + 1;

      startInt.push(startIntervalTime.format(outputFormat));

      if (
        counter === 16 &&
        endIntervalTime.format(outputFormat) !== value.endTime
      ) {
        finishInt.push(value.endTime);
      } else {
        finishInt.push(endIntervalTime.format(outputFormat));
      }

      startIntervalTime.add(dif, "ms");
      endIntervalTime.add(dif, "ms");
    }

    return { startInt, finishInt };
  };

  const setFinishTime = (value: any) => {
    //если фишиш < старта => добавляем к финиш моменту 1день
    const isAfter = moment(values.start).isSameOrAfter(moment(value));

    isAfter
      ? setFieldValue("finish", moment(value).add(1, "days"))
      : setFieldValue("finish", value); //если фишиш > старта => записываем как есть

    if (values.finish) {
      //расчет интервала
      //@ts-ignore
      const duration = moment.duration(values.finish.diff(values.start));
      const interval = Math.abs(duration.as("milliseconds")) + 1000;

      intervalChecking(duration);

      setFieldValue("interval", moment.utc(interval / 16).format("HH:mm"));
    }
  };

  const setArrays = () => {
    setFieldValue("arrays", prepareIntervals());
  };

  useEffect(() => {
    if (values.finish) {
      const finish = moment(values.start).isSameOrAfter(moment(values.finish))
        ? moment(values.finish).add(1, "days")
        : values.finish;

      //расчет интервала
      //@ts-ignore
      const duration = moment.duration(finish.diff(values.start));
      const interval = Math.abs(duration.as("milliseconds")) + 1000;

      setFieldValue("interval", moment.utc(interval / 16).format("HH:mm"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.finish]);

  useEffect(() => {
    if (!values.arrays.finishInt.length && values.interval) {
      setFieldValue("arrays", prepareIntervals());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.interval]);

  //Блокируем ввод времени если есть ивенты
  const disableTimePicker = values.events.length > 0;

  return (
    <FormikProvider value={formikCtx}>
      <Form onFinish={handleSubmit} form={form} id="edit">
        <Modal
          title={<b>Настройка расписания</b>}
          visible={!!isEdit}
          okButtonProps={{
            htmlType: "submit",
            form: "edit",
            disabled: !isValid || isSubmitting || values.events.length === 0,
          }}
          onOk={() => form.submit}
          okText="Сохранить"
          cancelText="Отменить"
          okType="primary"
          onCancel={handleCancel}
          className={style.modal}
          width={"max-content"}
        >
          <FormItem name="period" label="Время" required>
            <TimePicker
              name="start"
              bordered={false}
              showNow={false}
              format={"HH:mm"}
              placeholder="Начало"
              value={values.start}
              onSelect={(value) => {
                setFieldValue("start", value);
              }}
              minuteStep={10}
              disabled={disableTimePicker}
            />
            <TimePicker
              name="finish"
              bordered={false}
              showNow={false}
              format={"HH:mm"}
              placeholder="Конец"
              value={values.finish}
              onSelect={(value) => setFinishTime(value)}
              onBlur={() => setArrays()}
              minuteStep={10}
              disabled={disableTimePicker}
            />
          </FormItem>

          <div className={style.about}>
            <Text>Укажите временные интервалы в порядке использования.</Text>
          </div>

          <SettingsFormFields
            arrays={values.arrays}
            events={values.events}
            intValidRule={values.intValidRule}
            setFieldValue={setFieldValue}
          />
        </Modal>
      </Form>
    </FormikProvider>
  );
};
