import { Redirect, Route, RouteProps } from "react-router-dom";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const loggedUser = localStorage.getItem("username") || true;

  if (!loggedUser) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
