import { useAppDispatch } from "app/hooks";

//components
import { Switch, notification } from "antd";
import { CheckOutlined } from "@ant-design/icons";

//utils
import { groupsModeEnum } from "lib/constants";

//actions
import { manualGroup, autoGroup } from "feat/objects/actions";

//style
import style from "./Groups.module.css";

interface IProps {
  mode: string;
  group_id: number;
  schedule: string;
}

export const Control: React.FC<IProps> = ({ schedule, mode, group_id }) => {
  const dispatch = useAppDispatch();

  const auto = mode === groupsModeEnum.AUTO;

  const onChange = () => {
    if (auto && schedule !== null) {
      dispatch(manualGroup(group_id));
    }
    if (mode === groupsModeEnum.MANUAL && schedule !== null) {
      dispatch(autoGroup(group_id));
    }

    if (schedule === null) {
      notification["error"]({
        message: `Не выбрано расписание`,
      });
    }
  };

  return (
    <div className={style.control}>
      <Switch
        checked={auto}
        className={style.switch}
        //loading={loading}
        onChange={onChange}
        checkedChildren={<CheckOutlined />}
      />

      <span>{schedule ? schedule : ""}</span>
    </div>
  );
};
