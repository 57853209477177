import { request } from "lib/api";

import { ILogin } from "types";

export const login = async ({ username, password }: ILogin) => {
  const resp = await fetch("/api/v1/token-json", {
    method: "POST",
    body: JSON.stringify({ username, password }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await resp.json();

  if (resp.status !== 200) {
    throw new Error("Failed to logout");
  }

  return data;
};

export const logout = async () => {
  const resp = await request(`/logout`, "DELETE");
  const data = await resp.json();

  if (resp.status !== 200) {
    throw new Error("Failed to logout");
  }

  return data;
};

const auth = {
  login,
  logout,
};

export default auth;
