import { createAsyncThunk } from "@reduxjs/toolkit";

//components
import { notification } from "antd";

//types
import {
  IGroups,
  ThunkAPI,
  IDeleteGroup,
  IDimmGroup,
  ISchedule,
  ILamp,
  IEditLampGroup,
  IEditGroup,
  IEditSchedule,
  IEvent,
} from "types";

export const getGroups = createAsyncThunk<IGroups[], undefined, ThunkAPI>(
  "objects/getGroups",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.getGroups();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGroup = createAsyncThunk<any, string, ThunkAPI>(
  "objects/createGroup",
  async (group_name, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      const group_id = await api.objects.createGroup(group_name);

      dispatch(getGroups());

      notification["success"]({
        message: `Группа ${group_name} создана успешно`,
      });

      return { group_id, group_name };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const testGroup = createAsyncThunk<any, number, ThunkAPI>(
  "objects/testGroup",
  async (group_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.testGroup(group_id);

      notification["success"]({
        message: "Тест прошел успешно",
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGroup = createAsyncThunk<any, IDeleteGroup, ThunkAPI>(
  "objects/deleteGroup",
  async (
    { group_id, new_group },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const data = await api.objects.deleteGroup({ group_id, new_group });

      dispatch(getGroups());

      notification["success"]({
        message: "Группа светильников удалена",
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dimmGroup = createAsyncThunk<any, IDimmGroup, ThunkAPI>(
  "objects/dimmGroup",
  async (
    { group_id, percent },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const data = await api.objects.dimmGroup({ group_id, percent });

      dispatch(getGroups());

      notification["success"]({
        message: "Успешно",
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const manualGroup = createAsyncThunk<any, number, ThunkAPI>(
  "objects/manualGroup",
  async (group_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.objects.manualGroup(group_id);

      notification["success"]({
        message: "Успешно",
      });

      return group_id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const autoGroup = createAsyncThunk<any, number, ThunkAPI>(
  "objects/autoGroup",
  async (group_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.objects.autoGroup(group_id);

      notification["success"]({
        message: "Успешно",
      });

      return group_id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editGroup = createAsyncThunk<IEditGroup, IEditGroup, ThunkAPI>(
  "objects/editGroup",
  async (
    { group_name, schedule_id, group_id, schedule_name },
    { extra: { api }, rejectWithValue }
  ) => {
    const body = schedule_id ? { schedule_id, group_name } : { group_name };
    try {
      await api.objects.editGroup({
        group_id,
        body: JSON.stringify(body),
      });

      notification["success"]({
        message: "Отредактировано успешно",
      });

      return { schedule_name, schedule_id, group_id, group_name };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSchedules = createAsyncThunk<ISchedule[], undefined, ThunkAPI>(
  "objects/getSchedules",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.getSchedules();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLamps = createAsyncThunk<ILamp[], undefined, ThunkAPI>(
  "objects/getLamps",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.getLamps();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const testLamp = createAsyncThunk<any, number, ThunkAPI>(
  "objects/testLamp",
  async (lamp_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.testLamp(lamp_id);

      notification["success"]({
        message: "Тест прошел успешно",
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editLampGroup = createAsyncThunk<any, IEditLampGroup, ThunkAPI>(
  "objects/editLampGroup",
  async (
    { lamp_id, groups },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const data = await api.objects.editLampGroup({ lamp_id, groups });

      dispatch(getLamps());

      notification["success"]({
        message: "Отредактировано успешно",
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSchedule = createAsyncThunk<any, string, ThunkAPI>(
  "objects/createSchedule",
  async (schedule_name, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      const group_id = await api.objects.createSchedule(schedule_name);

      dispatch(getSchedules());

      notification["success"]({
        message: `Расписание ${schedule_name} создано успешно`,
      });

      return { group_id, schedule_name };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduleEvents = createAsyncThunk<any, number, ThunkAPI>(
  "objects/getScheduleEvents",
  async (schedule_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.getScheduleEvents(schedule_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSchedule = createAsyncThunk<any, number, ThunkAPI>(
  "objects/deleteSchedule",
  async (schedule_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.objects.deleteSchedule(schedule_id);

      notification["success"]({
        message: `Расписание удалено`,
      });

      return schedule_id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editSchedule = createAsyncThunk<IEvent[], IEditSchedule, ThunkAPI>(
  "objects/editSchedule",
  async (body, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      const data = await api.objects.editSchedule(body);

      dispatch(getSchedules()); ////TODO сделать через стор /////////////////////////////

      notification["success"]({
        message: `Расписание обновлено`,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
