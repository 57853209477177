import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

//types
import {
  IDeleteGroup,
  IDimmGroup,
  IEditLampGroup,
  IEditGroup,
  IEditSchedule,
} from "types";

export const getGroups = async () => {
  const resp = await request(`/group`, "GET");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const createGroup = async (group_name: string) => {
  const resp = await request(`/group?group_name=${group_name}`, "POST");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const testGroup = async (group_id: number) => {
  const resp = await request(`/group/${group_id}/test`, "POST");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const deleteGroup = async ({ group_id, new_group }: IDeleteGroup) => {
  const params = new_group ? `?new_group=${new_group}` : "";
  const resp = await request(`/group/${group_id}${params}`, "DELETE");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const dimmGroup = async ({ group_id, percent }: IDimmGroup) => {
  const resp = await request(
    `/group/${group_id}/dimm?percent=${percent}`,
    "POST"
  );

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const manualGroup = async (group_id: number) => {
  const resp = await request(`/group/${group_id}/manual`, "POST");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const autoGroup = async (group_id: number) => {
  const resp = await request(`/group/${group_id}/auto`, "POST");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const editGroup = async ({
  body,
  group_id,
}: {
  body: string;
  group_id: number | boolean;
}) => {
  const resp = await request(`/group/${group_id}`, "POST", body);

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const getSchedules = async () => {
  const resp = await request(`/schedule`, "GET");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const getLamps = async () => {
  const resp = await request(`/lamp`, "GET");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const testLamp = async (lamp_id: number) => {
  const resp = await request(`/lamp/${lamp_id}/test`, "POST");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const editLampGroup = async ({ lamp_id, groups }: IEditLampGroup) => {
  const body = JSON.stringify({ groups });
  const resp = await request(`/lamp/${lamp_id}/group`, "PATCH", body);

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const createSchedule = async (schedule_name: string) => {
  const resp = await request(
    `/schedule?schedule_name=${schedule_name}`,
    "POST"
  );

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const getScheduleEvents = async (schedule_id: number) => {
  const resp = await request(`/schedule/${schedule_id}`, "GET");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const deleteSchedule = async (schedule_id: number) => {
  const resp = await request(`/schedule?schedule_id=${schedule_id}`, "DELETE");

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const editSchedule = async (body: IEditSchedule) => {
  const resp = await request(
    `/schedule/${body.schedule_id}`,
    "POST",
    JSON.stringify(body.events)
  );

  if (resp.status !== 200) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const objects = {
  getGroups,
  createGroup,
  testGroup,
  deleteGroup,
  dimmGroup,
  manualGroup,
  autoGroup,
  editGroup,
  getSchedules,
  getLamps,
  testLamp,
  editLampGroup,
  createSchedule,
  getScheduleEvents,
  deleteSchedule,
  editSchedule,
};

export default objects;
