//components
import { Layout } from "antd";
import { MainMenu } from "common/MainMenu";
import { AppLogo } from "common/AppLogo/AppLogo";
import { MobileMenu } from "common/MobileMenu";

//style
import style from "./MenuLayout.module.css";

const { Header, Content, Footer } = Layout;

export const withMenuLayout = (
  Page: React.ComponentType<any>,
  layoutProps?: any
): any => {
  const innerPage = (props: any) => (
    <MenuLayout {...layoutProps}>
      <Page {...props} />
    </MenuLayout>
  );
  return innerPage;
};

const MenuLayout: React.FC = ({ children }) => {
  return (
    <Layout className={style.layout}>
      <Header className={style.header}>
        <div className={style.logoBox}>
          <AppLogo />
          <h1 className={style.title}>Управление</h1>
        </div>

        <MainMenu />
      </Header>

      <MobileMenu />

      <Content className={style.content}>{children}</Content>

      <Footer className={style.footer}>
        ©uSpherum. Система управления наружным освещением
      </Footer>
    </Layout>
  );
};
