//components
import { Spin } from "antd";

//style
import style from "./Loading.module.css";

export const Loading = () => {
  return (
    <div className={style.loading}>
      <Spin size="large" />
    </div>
  );
};
