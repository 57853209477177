import { createSelector } from "@reduxjs/toolkit";

//utils
import { lampsSorterEnum } from "lib/constants";

// types
import { RootState } from "app/store";

export const selectLampsList = (state: RootState) => state.lamps.lamps;
export const selectFilter = (state: RootState) => state.lamps.filter;

export const selectFilteredLamps = createSelector(
  [selectLampsList, selectFilter],
  (lamps, filter) =>
    lamps.filter((lamp) => {
      let filtered = true;

      let lampGroupsIds = lamp.groups.map((i) => i.group_id);

      if (filter.sorter === lampsSorterEnum.WHTG && lamp.groups.length) {
        filtered = false;
      }

      if (filter.group && !lampGroupsIds.includes(filter.group)) {
        filtered = false;
      }

      return filtered;
    })
);

export const selectCounter = createSelector(
  [selectLampsList, selectFilter],
  (lamps, filter) => {
    const lampsList = lamps.filter((lamp) => {
      let filtered = true;

      let lampGroupsIds = lamp.groups.map((i) => i.group_id);

      if (filter.group && !lampGroupsIds.includes(filter.group)) {
        filtered = false;
      }

      return filtered;
    });

    const lampsMap = {
      all: lampsList.length,
      wthGroup: lampsList.filter((lamp) => lamp.groups.length === 0).length,
    };

    return lampsMap;
  }
);
