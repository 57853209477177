import { createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { history } from "utils/history";

//types
import { ILogin, ThunkAPI } from "types";

export const login = createAsyncThunk<string, ILogin, ThunkAPI>(
  "auth/login",
  async ({ username, password }, { extra: { api }, rejectWithValue }) => {
    try {
      await api.auth.login({ username, password });

      localStorage.setItem("username", username);

      history.push("/");

      return username;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk<null, null, ThunkAPI>(
  "auth/logout",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.auth.logout();

      localStorage.removeItem("username");

      history.push("/login");

      return null;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);
