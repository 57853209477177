import { ColumnsType } from "antd/es/table";
import { TimetableTableActions } from "./TimetableTableActions";

//types
import { ISchedule } from "types";

export const TimetableTableColumns: ColumnsType<ISchedule> = [
  {
    title: "Расписание",
    dataIndex: "schedule_name",
  },
  {
    title: "События",
    dataIndex: "event_quantity",
    align: "right",
  },
  {
    title: "ID",
    dataIndex: "schedule_id",
    align: "right",
  },
  {
    title: "Действия",
    dataIndex: "actions",
    render: (_, record) => <TimetableTableActions timetable={record} />,
  },
];
