import { useState } from "react";

//components
import { Collapse, Space } from "antd";
import { Control } from "../Control";
import { Dimm } from "../Dimm/Dimm";
import { TableActions } from "../TableActions";

//types
import { IGroups } from "types";

//styles
import styles from "./GroupsCollapse.module.css";

const { Panel } = Collapse;

interface IProps {
  groupsList: IGroups[];
}

export const GroupsCollapse: React.FC<IProps> = ({ groupsList }) => {
  const [activeKey, setActiveKey] = useState<string | string[]>("");

  return (
    <Collapse
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
      accordion
      bordered
      className={styles.root}
    >
      {groupsList.map((group) => {
        return (
          <Panel
            header={
              <span
                className={
                  activeKey === group.group_id.toString()
                    ? styles.openTitle
                    : ""
                }
              >
                {group.group_name}
              </span>
            }
            key={group.group_id.toString()}
            showArrow={false}
          >
            <Space direction="vertical" size={20} className={styles.space}>
              <div>
                <span>Светильники: </span>
                {group.lamp_quantity}
              </div>

              <div>
                <span>Расписание:</span>
                <Control
                  mode={group.mode}
                  group_id={group.group_id}
                  schedule={group.schedule_name}
                />
              </div>

              <div>
                <span>Диммирование:</span>
                <Dimm
                  dimm={group.curr_percent}
                  group_id={group.group_id}
                  mode={group.mode}
                  lamps={group.lamp_quantity}
                />
              </div>

              <div className={styles.actions}>
                <TableActions group_id={group.group_id} />
              </div>
            </Space>
          </Panel>
        );
      })}
    </Collapse>
  );
};
